import { useMemo } from 'react';

import { BasicModal, BasicModalSize } from '@ac/kiosk-components';

import { KeyGenerationStatus } from '../../store/interfaces';

import { Failed, Initial, Pending, PropsTypes, Success } from './types';

import './KeyGenerationModal.scss';

const keyGenerationModalTypeMap = {
  [KeyGenerationStatus.initial]: Initial,
  [KeyGenerationStatus.pending]: Pending,
  [KeyGenerationStatus.success]: Success,
  [KeyGenerationStatus.failed]: Failed,
};

interface KeyGenerationModalProps extends PropsTypes {
  type: KeyGenerationStatus;
}

export const KeyGenerationModal = ({
  type,
  ...props
}: KeyGenerationModalProps): JSX.Element => {
  const ContentType = useMemo((): React.ComponentType<PropsTypes> => {
    return keyGenerationModalTypeMap[type];
  }, [type]);

  return (
    <BasicModal
      size={BasicModalSize.md}
      className="with-default-kiosk-components-theme key-generation-modal-wrapper"
    >
      <ContentType {...props} />
    </BasicModal>
  );
};
