import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DatePickerField,
  FormHeader,
  FormRadio,
  FormTextField,
} from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import {
  change,
  Field,
  Form,
  getFormSyncErrors,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import {
  getNumberOfTries,
  getTooMuchReservations,
} from 'store/reservation/selectors';
import { AdditionalData, AuthorizationData } from 'types/Api/Reservation';
import Store from 'types/Store';
import { Configurator, DateManager } from 'utils';
import {
  confirmationNumber,
  date,
  isFormValid,
  number,
  required,
} from 'utils/Validator';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './AuthorizationForm.style';

const { SHOW_CONF_NUMBER, SHOW_DUE_DATE, SHOW_NO_OF_NIGHTS } =
  Configurator.switchCodes;

interface PassedProps {
  onSubmit?: (values: AuthorizationData) => void;
  setSubmitState: (isFormUnvalid: boolean) => void;
  isLoading?: boolean;
  disableAdditionalData?: boolean;
  securityConfirmationFieldEnabled?: boolean;
}

interface AuthorizationFormProps
  extends PassedProps,
    InjectedFormProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  onChange: (form: string, field: string, value: any) => void;
  tooMuchReservations: boolean;
  formErrors: { [key: string]: string };
  authorizationForm: AuthorizationData;
  numberOfTries: number;
}

class AuthorizationForm extends PureComponent<AuthorizationFormProps> {
  public static defaultProps = {
    isLoading: false,
    disableAdditionalData: false,
  };

  public componentDidUpdate() {
    const { setSubmitState, formErrors } = this.props;

    setSubmitState(isFormValid(this.props) && !Object.keys(formErrors).length);
  }

  private onlySurname = Configurator.getSwitch(
    Configurator.switchCodes.CHECK_IN_BY_SURNAME_ONLY,
    false
  );

  public render() {
    const { classes, t, handleSubmit, authorizationForm, numberOfTries } =
      this.props;
    const businessDate = Configurator.propertyBusinessDate;
    const isSecondFactorDisabled = numberOfTries > 1;

    return (
      <div>
        <FormHeader
          title={t('OR_SEARCH_FOR_RESERVATION')}
          subtitle={t('ALL_FIELDS_MANDATORY')}
        />
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Field
                name="lastName"
                component={FormTextField}
                validate={[required]}
                label={t('LAST_NAME')}
                className={classes.formInput}
                data-test-selector="check-in-authorization-last-name-field"
              />
            </Grid>
            {!this.onlySurname && (
              <Grid item xs={12} sm={12} md={12}>
                <Field
                  name="identificationNumber"
                  validate={[confirmationNumber, required]}
                  component={FormTextField}
                  label={t('CONFIRMATION_NUMBER')}
                  className={classes.formInput}
                  data-test-selector="check-in-authorization-surname-only-confirmation-number-field"
                />
              </Grid>
            )}

            {!!numberOfTries && this.onlySurname && (
              <>
                {this.showSecondFactor && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography className={classes.subtitle}>
                        {t('TOO_MUCH_RESERVATIONS')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Field
                        validate={[required]}
                        name="additionalData"
                        component={FormRadio}
                        data={this.getRadioButtonsData()}
                        onChange={() => this.changeRadio()}
                        disabled={isSecondFactorDisabled}
                        data-test-selector="check-in-authorization-additional-data-radio"
                      />
                    </Grid>
                  </>
                )}
                {authorizationForm?.additionalData ===
                  AdditionalData.ConfirmationNumber && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Field
                      name="identificationNumber"
                      validate={[confirmationNumber, required]}
                      component={FormTextField}
                      label={t('CONFIRMATION_NUMBER')}
                      className={classes.formInput}
                      disabled={isSecondFactorDisabled}
                      data-test-selector="check-in-authorization-additional-data-confirmation-number-field"
                    />
                  </Grid>
                )}
                {authorizationForm?.additionalData ===
                  AdditionalData.DepartureDate && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Field
                      name="departureDate"
                      validate={[required, date]}
                      component={DatePickerField}
                      label={t('DEPARTURE_DATE')}
                      className={classes.formInput}
                      onChange={(value: any) =>
                        this.updateNumberOfNights(value)
                      }
                      minDate={DateManager.getStartOfTheDay(businessDate)}
                      disabled={isSecondFactorDisabled}
                      data-test-selector="check-in-authorization-additional-data-departure-date-picker"
                    />
                  </Grid>
                )}
                {authorizationForm?.additionalData ===
                  AdditionalData.NumberOfNights && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Field
                      name="numberOfNights"
                      validate={[number, required]}
                      component={FormTextField}
                      label={t('NUMBER_OF_NIGHTS')}
                      className={classes.formInput}
                      onChange={(event: any) =>
                        this.updateDepartureDate(event.target.value)
                      }
                      disabled={isSecondFactorDisabled}
                      data-test-selector="check-in-authorization-additional-data-number-of-nights-field"
                    />
                  </Grid>
                )}
              </>
            )}
            {this.showConfirmationNumber && (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={classes.subtitle}>
                    {t('PROVIDE_CONFIRMATION_NUMBER')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    name="identificationNumber"
                    validate={[confirmationNumber, required]}
                    component={FormTextField}
                    label={t('CONFIRMATION_NUMBER')}
                    className={classes.formInput}
                    data-test-selector="check-in-authorization-confirmation-number-field"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </div>
    );
  }

  private get showConfirmationNumber() {
    const { numberOfTries, securityConfirmationFieldEnabled } = this.props;
    if (!this.onlySurname) return false;
    if (securityConfirmationFieldEnabled) return true;
    if (this.showSecondFactor) return numberOfTries > 1;

    return numberOfTries > 0;
  }

  private get showSecondFactor() {
    const switches = [SHOW_CONF_NUMBER, SHOW_DUE_DATE, SHOW_NO_OF_NIGHTS];

    return this.onlySurname && switches.some((s) => Configurator.getSwitch(s));
  }

  private getRadioButtonsData = () => {
    const { t } = this.props;
    const showConfNumber = Configurator.getSwitch(SHOW_CONF_NUMBER);
    const showDueDate = Configurator.getSwitch(SHOW_DUE_DATE);
    const showNoOfNights = Configurator.getSwitch(SHOW_NO_OF_NIGHTS);
    const additionalData = [];
    if (showConfNumber) {
      additionalData.push({
        title: t('CONFIRMATION_NUMBER'),
        value: AdditionalData.ConfirmationNumber,
      });
    }
    if (showDueDate) {
      additionalData.push({
        title: t('DEPARTURE_DATE'),
        value: AdditionalData.DepartureDate,
      });
    }
    if (showNoOfNights) {
      additionalData.push({
        title: t('NUMBER_OF_NIGHTS'),
        value: AdditionalData.NumberOfNights,
      });
    }

    return additionalData.length > 1 && additionalData;
  };

  private changeRadio = () => {
    const { authorizationForm } = this.props;
    const businessDate = Configurator.propertyBusinessDate;
    if (!authorizationForm.departureDate) {
      this.updateDepartureDate(1);
      this.updateNumberOfNights(DateManager.addDays(businessDate, 1));
    }
  };

  private updateDepartureDate = (numberOfNights?: number) => {
    const { authorizationForm, onChange } = this.props;
    const departure = DateManager.addDays(
      Configurator.propertyBusinessDate,
      numberOfNights || 0,
      Configurator.dateFormat?.shortDateFormat
    );
    if (authorizationForm.departureDate !== departure) {
      onChange(ids.AUTHORIZATION_FORM, 'departureDate', departure);
    }
  };

  private updateNumberOfNights = (departureDate?: string) => {
    const { authorizationForm, onChange } = this.props;
    const businessDate = Configurator.propertyBusinessDate;
    const numberOfNights = departureDate
      ? DateManager.getDiffBetween(businessDate, departureDate)
      : 1;
    if (authorizationForm.numberOfNights !== numberOfNights) {
      onChange(ids.AUTHORIZATION_FORM, 'numberOfNights', numberOfNights);
    }
  };
}

const mapStateToProps = (state: Store) => ({
  authorizationForm: getFormValues(ids.AUTHORIZATION_FORM)(state),
  formErrors: getFormSyncErrors(ids.AUTHORIZATION_FORM)(state),
  tooMuchReservations: getTooMuchReservations(state),
  numberOfTries: getNumberOfTries(state),
});

const mapDispatchToProps = {
  onChange: change,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  reduxForm({ form: ids.AUTHORIZATION_FORM }),
  connect(mapStateToProps, mapDispatchToProps)
)(AuthorizationForm) as (props: PassedProps) => JSX.Element;
