import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Button,
  ButtonPattern,
  Color,
  Flex,
  FlexDirection,
  Icon,
  IconTypes,
  JustifyContent,
  Size,
  Table,
  Text,
  TextWeight,
} from '@ac/kiosk-components';
import { TableConfig } from '@ac/kiosk-components/dist/components/layoutComponents/Table/types';

import { CheckInAvailableReservationDto } from '@gss/api/KioskApi/entries';

import { mapMultiRoomReservationData } from '../../utils';

import './MultiRoomTable.scss';

const TABLE_CONFIG: TableConfig = {
  align: AlignItems.flexStart,
  justify: JustifyContent.flexEnd,
  columns: [
    { sizeSm: '1fr', justify: JustifyContent.flexStart },
    { sizeSm: 'auto', justify: JustifyContent.flexStart },
    { sizeSm: 'max-content' },
    { sizeSm: 'max-content' },
    { sizeSm: 'max-content' },
    { sizeSm: 'max-content' },
  ],
};

interface Props {
  availableReservations: CheckInAvailableReservationDto[];
  multiRoomConfirmationNumber?: string;
  onCheckInClick?: (reservationId: string) => void;
}

export const MultiRoomTable = ({
  availableReservations,
  multiRoomConfirmationNumber,
  onCheckInClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const presentationData = useMemo(
    () => availableReservations.map(mapMultiRoomReservationData),
    [availableReservations]
  );

  return (
    <Flex direction={FlexDirection.column} className="multi-room-table-wrapper">
      <Table config={TABLE_CONFIG}>
        <Table.Header>
          <Table.Cell>
            {t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.MAIN_GUEST')}
          </Table.Cell>
          <Table.Cell>
            {t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.STAY')}
          </Table.Cell>
          <Table.Cell>
            {t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.ROOM')}
          </Table.Cell>
          <Table.Cell>
            {t(
              'CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.CHECKED_GUESTS'
            )}
          </Table.Cell>
          <Table.Cell>
            {t('CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.CONFIRMATION')}
          </Table.Cell>
          <Table.Cell />
        </Table.Header>
        <Table.Body>
          {presentationData?.map((data) => (
            <Table.Row key={data.id}>
              <Table.Cell>
                <Flex alignItems={AlignItems.center} className="gap-sm">
                  {data.isFullyCheckedIn && (
                    <Icon
                      size={Size.sm}
                      type={IconTypes.checkFilled}
                      color={Color.success}
                    />
                  )}
                  {data.guestName}
                </Flex>
              </Table.Cell>
              <Table.Cell>{data.stay}</Table.Cell>
              <Table.Cell>{data.room}</Table.Cell>
              <Table.Cell>{data.checkedInGuests}</Table.Cell>
              <Table.Cell>{data.confirmation}</Table.Cell>
              <Table.Cell className="padding-none">
                <Button
                  pattern={ButtonPattern.tertiary}
                  onClick={(): void => onCheckInClick?.(data.id)}
                >
                  {t(
                    'CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.CHECK_IN'
                  )}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Flex className="spacing-left-auto spacing-top-lg gap-sm">
        <Text weight={TextWeight.bold}>
          {t(
            'CHECK_IN_FLOW.VIEWS.MULTI_ROOM.SELECTION_SECTION.MR_CONFIRMATION_NO'
          )}
          :
        </Text>
        <Text>{multiRoomConfirmationNumber}</Text>
      </Flex>
    </Flex>
  );
};
