import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Button,
  ButtonPattern,
  Circular,
  Color,
  Flex,
  FlexDirection,
  IconTypes,
  JustifyContent,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { PropsTypes } from '../propsTypes';

export const Initial = ({
  keyNumber,
  amount,
  onCancel,
  onConfirm,
}: PropsTypes): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        direction={FlexDirection.column}
        alignItems={AlignItems.center}
        className="spacing-top-md spacing-bottom-lg gap-lg"
      >
        <Circular
          size={88}
          iconColor={Color.primary}
          icon={IconTypes.cardKey}
          className="key-generation-circular"
        />
        <Text size={TextSize.xlg}>
          {t(
            'VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.MAKE_KEY_COUNTER',
            {
              current: keyNumber,
              max: amount,
            }
          )}
        </Text>
        <Text>
          {t(
            'VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.PLACE_KEY_ON_CODER'
          )}
        </Text>
      </Flex>

      <Flex justifyContent={JustifyContent.spaceBetween}>
        <Button pattern={ButtonPattern.secondary} onClick={onCancel}>
          {t('SHARED.CANCEL')}
        </Button>
        <Button onClick={onConfirm}>
          {t('VIEWS.KEY_FLOW.SHARED.MAKE_KEY')}
        </Button>
      </Flex>
    </>
  );
};
