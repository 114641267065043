import { formatProfileName, TitleOrders } from '@ac/library-api';

import { CheckInGuestDto } from '@gss/api/KioskApi/entries';
import { reduxStore } from '@gss/store';
import { getExternalSettings } from '@gss/store/settings/selectors';
import { DateTimeManager } from '@gss/utils/dateTime';

export interface AccompanyGuestData {
  id: string;
  guestName: string;
  stay: string;
  isCheckedIn: boolean;
}

export const mapGuestData = (guest: CheckInGuestDto): AccompanyGuestData => {
  const externalSettings = getExternalSettings(reduxStore.getState());
  const titleOrder =
    externalSettings?.TITLE_ORDER || TitleOrders.TitleFirstNameLastName;

  return {
    id: guest.id,
    guestName: formatProfileName(titleOrder, {
      firstName: guest.firstName,
      lastName: guest.lastName,
      title: guest.title?.description,
      middleName: undefined,
      suffix: undefined,
    }),
    stay: DateTimeManager.getFormattedPeriodDate(
      guest.arrivalDate,
      guest.departureDate
    ),
    isCheckedIn: guest.checkedIn,
  };
};
