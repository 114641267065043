import { createSelector } from 'reselect';

import { ApiError } from '@ac/library-api';

import {
  CheckInAvailableReservationDto,
  CheckInProfileDetailsDto,
  CheckInReservationDetailsDto,
} from '@gss/api/KioskApi/entries';
import { Store } from '@gss/store';
import { AuthProcessStatus } from '@gss/types/authProcess';
import { ComparisonType, DateTimeManager } from '@gss/utils/dateTime';

import { StartCheckInProcessPayload } from './interfaces';

export const getCheckInProcessFetchState = (state: Store): boolean =>
  Object.values(state.checkInFlow.fetching).includes(true);

export const getCheckInProcessErrors = (
  state: Store
): Array<ApiError | Error> => state.checkInFlow.errors;

export const getCheckInAuthProcessStatus = (
  state: Store
): AuthProcessStatus | undefined => state.checkInFlow.authenticationStatus;

export const getCheckInAuthData = (
  state: Store
): StartCheckInProcessPayload | undefined => state.checkInFlow.authData;

export const getCheckInProcessId = (state: Store): string | undefined =>
  state.checkInFlow.processId;

export const getCheckInReservationId = (state: Store): string | undefined =>
  state.checkInFlow.reservationId;

export const getCheckInProfileId = (state: Store): string | undefined =>
  state.checkInFlow.profileId;

export const getIsCheckInSecondFactorNeeded = (state: Store): boolean =>
  state.checkInFlow.isSecondFactorNeeded;

export const getCheckInAvailableReservations = (
  state: Store
): CheckInAvailableReservationDto[] | undefined =>
  state.checkInFlow.availableReservations;

export const getCheckInReservationDetails = (
  state: Store
): CheckInReservationDetailsDto | undefined =>
  state.checkInFlow.reservationDetails;

export const getCheckInProfileDetails = (
  state: Store
): CheckInProfileDetailsDto | undefined => state.checkInFlow.profileDetails;

export const getIsCheckInGuestAuthenticated = createSelector(
  [getCheckInAuthProcessStatus],
  (authenticationStatus) => {
    return authenticationStatus === AuthProcessStatus.success;
  }
);

export const getIsMultiRoomReservation = createSelector(
  [getCheckInAvailableReservations],
  (availableReservations) => {
    return (availableReservations?.length ?? 0) > 1;
  }
);

export const getSelectedReservation = createSelector(
  [getCheckInAvailableReservations, getCheckInReservationId],
  (
    availableReservations,
    reservationId
  ): CheckInAvailableReservationDto | undefined => {
    return availableReservations?.find(
      (reservation) => reservation.id === reservationId
    );
  }
);

export const getSelectedProfile = createSelector(
  [getSelectedReservation, getCheckInProfileId],
  (reservation, profileId): CheckInAvailableReservationDto | undefined => {
    return [
      reservation?.guest,
      ...(reservation?.accompanyingGuests || []),
    ].find((profile) => profile?.id === profileId);
  }
);

export const getIsReservationWithAccompanyGuests = createSelector(
  [getSelectedReservation],
  (reservation) => {
    return !!reservation?.accompanyingGuests?.length;
  }
);

export const getIsCheckInProcessReady = createSelector(
  [getCheckInProcessId, getCheckInReservationDetails, getCheckInProfileDetails],
  (processId, reservationDetails, profileDetails) => {
    return Boolean(processId && reservationDetails && profileDetails);
  }
);

export const getIsDayUseReservation = createSelector(
  [getCheckInReservationDetails],
  (reservationDetails): boolean => {
    return reservationDetails
      ? DateTimeManager.comparison(
          reservationDetails.arrivalDate,
          reservationDetails.departureDate,
          { type: ComparisonType.isSame }
        )
      : false;
  }
);
