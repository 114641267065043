import { formatProfileName, TitleOrders } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { CheckInAvailableReservationDto } from '@gss/api/KioskApi/entries';
import { reduxStore } from '@gss/store';
import { getExternalSettings } from '@gss/store/settings/selectors';
import { DateTimeManager } from '@gss/utils/dateTime';

export interface MultiRoomReservationData {
  id: string;
  guestName: string;
  stay: string;
  room?: string;
  checkedInGuests: string;
  isFullyCheckedIn: boolean;
  confirmation?: string;
}

export const mapMultiRoomReservationData = (
  reservation: CheckInAvailableReservationDto
): MultiRoomReservationData => {
  const externalSettings = getExternalSettings(reduxStore.getState());
  const titleOrder =
    externalSettings?.TITLE_ORDER || TitleOrders.TitleFirstNameLastName;

  const allReservationQuests = [
    reservation.guest,
    ...(reservation.accompanyingGuests || []),
  ].filter(isDefined);

  const checkedInGuests = allReservationQuests.filter(
    (guest) => guest?.checkedIn
  );

  return {
    id: reservation.id,
    guestName: formatProfileName(titleOrder, {
      firstName: reservation.guest?.firstName,
      lastName: reservation.guest?.lastName,
      title: reservation.guest?.title?.description,
      middleName: undefined,
      suffix: undefined,
    }),
    stay: DateTimeManager.getFormattedPeriodDate(
      reservation.arrivalDate,
      reservation.departureDate
    ),
    room: reservation.room?.code,
    checkedInGuests: `${checkedInGuests.length}/${allReservationQuests.length}`,
    isFullyCheckedIn: checkedInGuests.length === allReservationQuests.length,
    confirmation: reservation.confirmationNumber,
  };
};
