import { createSelector } from 'reselect';
import { isFetching as isAvailabilityFetching } from 'store/availability/selectors';
import {
  getCashieringErrors,
  getCompanyIdLinkedToReservation,
  isFetching as isCashieringFetching,
} from 'store/cashiering/selectors';
import {
  checkInProcessErrors,
  getIsCheckInProcessFetching,
} from 'store/checkInProcess/selectors';
import {
  getErrors as getFilesErrors,
  isFetching as isFilesFetching,
} from 'store/files/selectors';
import { getHousekeepingErrors } from 'store/housekeeping/selectors';
import {
  getAllProfileMemberships,
  getEmail,
  getGuestLinkedProfileIdsByRole,
  getMobile,
  getProfile,
  getProfileAddress,
  getProfileDetails,
  getProfileErrors,
  getProfileSecondSurname,
  isFetching as isProfileFetching,
} from 'store/profile/selectors';
import {
  getAdults,
  getChildren,
  getLinkedMembership,
  getNights,
  getReservation,
  getReservationErrors,
  isFetching as isReservationFetching,
  isReservationDayUse,
} from 'store/reservation/selectors';
import {
  getRoomErrors,
  getRoomNumber,
  getRoomTypeName,
} from 'store/room/selectors';
import { Address } from 'types/Api/Profile';
import { Configurator, DateManager } from 'utils';
import { getIdPhotos } from 'views/CheckInID/store/selectors';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';
import {
  getAllDistrictResults,
  getAllStateResults,
} from '@gss/store/lazyLoadedDictionary/selectors';
import { getMembershipDescription } from '@LEGACY/utils/memberships';

const formatAddress = (
  address: Address,
  states: Record<string, KioskStateDetails[]>,
  districts: Record<string, BaseKioskConfigurationEntity[]>,
  isDistrictEnabled: boolean
) => {
  const arrayOfStates = address.stateCode
    ? states?.[address.countryCode]
    : null;
  const stateDictionary = arrayOfStates?.find(
    (state) => state.code === address.stateCode
  );

  const arrayOfDistricts = address.districtId
    ? districts?.[address.countryCode]
    : null;
  const districtDictionary = arrayOfDistricts?.find(
    (district) => district.id === address.districtId
  );

  const addressInlinePart = [
    address.postCode,
    address.city,
    stateDictionary?.name,
    isDistrictEnabled ? districtDictionary?.description : undefined,
    address.countryCode,
  ]
    .filter(isDefined)
    .join(', ');

  return [address.addressLine1, address.addressLine2, addressInlinePart]
    .filter(isDefined)
    .join('\n');
};

export const getTitle = createSelector(getProfile, (profile) => {
  const { titleCode } = profile.details;
  if (!titleCode) return null;
  const title = Configurator.titles.find((elem) => elem.code === titleCode);

  return title ? Configurator.getDescription(title.description) : '';
});

export const getName = createSelector(
  getProfile,
  getTitle,
  getProfileSecondSurname,
  (profile, title, secondSurname) => {
    const { firstName, lastName } = profile.details;
    if (!firstName) return null;

    return [title, firstName, lastName, secondSurname].join(' ').trim();
  }
);

export const getFullMembership = createSelector(
  getLinkedMembership,
  getAllProfileMemberships,
  (membershipId, profileMemberships) => {
    const linkedMembership = profileMemberships.find(
      (membership) => membership.membershipId === membershipId
    );

    if (!linkedMembership?.isActive) {
      return undefined;
    }

    return getMembershipDescription(linkedMembership);
  }
);

export const getGuestDetails = createSelector(
  getProfileDetails,
  getProfileAddress,
  getAllStateResults,
  getAllDistrictResults,
  getEmail,
  getMobile,
  getName,
  getFullMembership,
  (profile, address, states, districts, email, mobile, name, membership) => {
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );

    return {
      name: name || '',
      membership: membership || '',
      address: address
        ? formatAddress(address, states, districts, isDistrictEnabled)
        : '',
      email: email ? email.details : '',
      mobile: mobile ? mobile.details : '',
      vipCode: profile.vipCode || '',
      taxId: profile.taxId || '',
    };
  }
);

export const getPrice = (price: number | string) =>
  typeof price === 'string' ? parseFloat(price) : price;

export const getReservationDetails = createSelector(
  getReservation,
  getAdults,
  getChildren,
  getNights,
  getRoomNumber,
  getRoomTypeName,
  isReservationDayUse,
  (
    reservation,
    adults,
    children,
    nights,
    roomNumber,
    roomName,
    isReservationDayUse
  ) => {
    const { arrivalDate: fromDate, departureDate: toDate } = reservation;

    const formattedFromDate = DateManager.getFormattedDate(fromDate);
    const formattedToDate = DateManager.getFormattedDate(toDate);
    const bookingPeriod = isReservationDayUse
      ? formattedToDate
      : `${formattedFromDate} - ${formattedToDate}`;

    return {
      adults,
      children,
      nights,
      roomNumber,
      roomName,
      totalPrice: reservation?.projectedRevenue.amount
        ? getPrice(reservation.projectedRevenue.amount)
        : 0,
      bookingPeriod,
    };
  }
);

export const getErrors = createSelector(
  getRoomErrors,
  getReservationErrors,
  getProfileErrors,
  getCashieringErrors,
  getHousekeepingErrors,
  checkInProcessErrors,
  getFilesErrors,
  (
    roomErrors,
    reservationErrors,
    profileErrors,
    cashieringErrors,
    housekeepingErrors,
    checkInProcessErrors,
    filesErrors
  ) => [
    ...roomErrors,
    ...reservationErrors,
    ...profileErrors,
    ...cashieringErrors,
    ...housekeepingErrors,
    ...checkInProcessErrors,
    ...filesErrors,
  ]
);

export const isLoading = createSelector(
  isCashieringFetching,
  isFilesFetching,
  isProfileFetching,
  isReservationFetching,
  isAvailabilityFetching,
  getIsCheckInProcessFetching,
  (
    cashiering,
    files,
    profile,
    reservation,
    availability,
    isCheckInProcessFetching
  ) =>
    cashiering ||
    files ||
    profile ||
    reservation ||
    availability ||
    isCheckInProcessFetching
);

export const getDocumentPhotos = createSelector(getIdPhotos, (photos) =>
  photos.length < 1 ? null : photos
);

export const getCompanyProfileIds = createSelector(
  getProfile,
  getCompanyIdLinkedToReservation,
  (profile, companyIdLinkedToReservation) =>
    companyIdLinkedToReservation
      ? [
          ...getGuestLinkedProfileIdsByRole(
            profile,
            Configurator.profileRoles.COMPANY
          ),
          companyIdLinkedToReservation,
        ]
      : getGuestLinkedProfileIdsByRole(
          profile,
          Configurator.profileRoles.COMPANY
        )
);
