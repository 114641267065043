import moment, { MomentInput, unitOfTime } from 'moment';

import { DateTimeFormat, TimeFormat } from '@ac/library-api';

import {
  DEFAULT_LONG_DATE_FORMAT,
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT_CODE,
  TIME_12H_FORMAT,
  TIME_24H_FORMAT,
} from '@gss/configs/constants';

export enum ComparisonType {
  isBefore = 'isBefore',
  isAfter = 'isAfter',
  isSame = 'isSame',
  isSameOrAfter = 'isSameOrAfter',
  isSameOrBefore = 'isSameOrBefore',
  isBetween = 'isBetween',
}

interface ComparisonOptions {
  type: ComparisonType;
  inputFormat?: string;
  granularity?: unitOfTime.StartOf;
}

interface DateTimeFormats {
  timeFormatCode: TimeFormat;
  timeFormat: string;
  longDateFormat: string;
  shortDateFormat: string;
}

export class DateTimeManager {
  private static storedDateTimeFormat: DateTimeFormat | undefined;

  private static get dateTimeSettings(): DateTimeFormats {
    const timeFormatCode =
      DateTimeManager.storedDateTimeFormat?.timeFormat ||
      DEFAULT_TIME_FORMAT_CODE;

    return {
      timeFormatCode,
      timeFormat:
        timeFormatCode === TimeFormat.H12 ? TIME_12H_FORMAT : TIME_24H_FORMAT,
      longDateFormat:
        DateTimeManager.storedDateTimeFormat?.longDateFormat ||
        DEFAULT_LONG_DATE_FORMAT,
      shortDateFormat:
        DateTimeManager.storedDateTimeFormat?.shortDateFormat ||
        DEFAULT_SHORT_DATE_FORMAT,
    };
  }

  public static saveDateTimeSettings(settings: DateTimeFormat): void {
    DateTimeManager.storedDateTimeFormat = settings;
  }

  public static getFormattedDateTime(
    date: MomentInput,
    inputFormat?: string,
    format?: string
  ): string {
    return moment(date, inputFormat).format(format);
  }

  public static getFormattedShortDate(
    date: MomentInput,
    inputFormat?: string
  ): string {
    return DateTimeManager.getFormattedDateTime(
      date,
      inputFormat,
      DateTimeManager.dateTimeSettings.shortDateFormat
    );
  }

  public static getFormattedLongDate(
    date: MomentInput,
    inputFormat?: string
  ): string {
    return DateTimeManager.getFormattedDateTime(
      date,
      inputFormat,
      DateTimeManager.dateTimeSettings.longDateFormat
    );
  }

  public static getFormattedTime(
    date: MomentInput,
    inputFormat?: string
  ): string {
    return DateTimeManager.getFormattedDateTime(
      date,
      inputFormat,
      DateTimeManager.dateTimeSettings.timeFormat
    );
  }

  public static comparison(
    dateTime: MomentInput,
    comparisonDateTime: MomentInput,
    options: ComparisonOptions
  ): boolean {
    const comparisonMethod = options.type;

    return moment(dateTime, options.inputFormat)[comparisonMethod](
      moment(comparisonDateTime, options.inputFormat),
      options.granularity
    );
  }

  public static getFormattedPeriodDate(
    dateFrom: MomentInput,
    dateTo: MomentInput,
    inputFormat?: string
  ): string {
    if (dateFrom === dateTo) {
      return DateTimeManager.getFormattedShortDate(dateFrom, inputFormat);
    }

    return [
      DateTimeManager.getFormattedShortDate(dateFrom, inputFormat),
      DateTimeManager.getFormattedShortDate(dateTo, inputFormat),
    ].join(' - ');
  }
}
