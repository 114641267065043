import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlignItems,
  Box,
  Flex,
  FlexDirection,
  Section,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { Body, LargeHeader } from '@gss/components/layout';
import { ETDNotification } from '@gss/components/utility';
import { ONE_SECOND, REDIRECT_TO_WELCOME_TIMEOUT } from '@gss/configs/timers';
import { useRouter } from '@gss/router';
import { closeMakeKeyProcess } from '@gss/store/flows/makeKeysFlow/actions';
import {
  getIsDayUseReservation,
  getMakeKeysReservationDetails,
} from '@gss/store/flows/makeKeysFlow/selectors';
import { getPropertyConfiguration } from '@gss/store/settings/selectors';
import { DateTimeManager } from '@gss/utils/dateTime';

import { MakeKeysFooter, MakeKeysView } from '../components';

import './KeyComplete.scss';

export const KeyComplete = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [counter, setCounter] = useState(
    REDIRECT_TO_WELCOME_TIMEOUT / ONE_SECOND
  );

  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const reservationDetails = useSelector(getMakeKeysReservationDetails);
  const isDayUse = useSelector(getIsDayUseReservation);

  const formattedEtd = DateTimeManager.getFormattedTime(
    reservationDetails?.etd
  );

  const closeProcess = (): void => {
    dispatch(
      closeMakeKeyProcess.trigger((): void => {
        router.goTo('WELCOME', { replace: true });
      })
    );
  };

  useEffect(() => {
    let currentCountValue: number = counter;

    const interval = setInterval(() => {
      currentCountValue = currentCountValue - 1;
      setCounter(currentCountValue);

      if (currentCountValue <= 0) {
        closeProcess();
        clearInterval(interval);
      }
    }, ONE_SECOND);

    return (): void => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MakeKeysView className="key-complete-container">
      <LargeHeader />
      <Body className="padding-vertical-md">
        <Section>
          <Flex direction={FlexDirection.column} className="gap-xlg">
            <Text>{t('VIEWS.KEY_FLOW.KEY_COMPLETE.YOUR_ROOM_NUMBER')}</Text>
            <Box>
              <Flex
                className="spacing-bottom-lg gap-xlg"
                alignItems={AlignItems.center}
              >
                <Text className="room-number">
                  {reservationDetails?.room?.code}
                </Text>
                {isDayUse && <ETDNotification etd={formattedEtd} />}
              </Flex>

              <Text size={TextSize.xs}>
                {reservationDetails?.roomFloor?.description}
              </Text>
              <Text size={TextSize.xs}>
                {reservationDetails?.roomLocation?.description}
              </Text>
            </Box>
            <Box>
              <Text>{t('VIEWS.KEY_FLOW.KEY_COMPLETE.PLEASANT_STAY')}</Text>
              <Text>{propertyConfiguration?.name}</Text>
            </Box>
          </Flex>
        </Section>
      </Body>
      <MakeKeysFooter
        hasSecondaryButton
        secondaryLabel={t('SHARED.CLOSE')}
        onSecondaryClick={closeProcess}
      >
        <Text
          hint
          className="text-right counter-notification spacing-left-auto spacing-right-md"
        >
          {t('VIEWS.KEY_FLOW.KEY_COMPLETE.CLOSE_SCREEN_NOTIFICATION', {
            counter,
          })}
        </Text>
      </MakeKeysFooter>
    </MakeKeysView>
  );
};
