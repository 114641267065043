import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Button,
  ButtonPattern,
  Circular,
  CircularType,
  Color,
  Flex,
  FlexDirection,
  IconTypes,
  JustifyContent,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { PropsTypes } from '../propsTypes';

export const Failed = ({
  keyNumber,
  amount,
  onCancel,
  onConfirm,
}: PropsTypes): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        direction={FlexDirection.column}
        alignItems={AlignItems.center}
        className="spacing-top-md spacing-bottom-lg gap-lg"
      >
        <Circular
          size={88}
          iconColor={Color.primary}
          icon={IconTypes.exclamationMark}
          type={CircularType.warning}
        />
        <Text size={TextSize.xlg}>
          {t('VIEWS.KEY_FLOW.COMPONENTS.KEY_GENERATION_MODAL.YOUR_KEY_FAiLED', {
            current: keyNumber,
            max: amount,
          })}
        </Text>
      </Flex>

      <Flex justifyContent={JustifyContent.spaceBetween}>
        <Button pattern={ButtonPattern.secondary} onClick={onCancel}>
          {t('SHARED.CANCEL')}
        </Button>
        <Button onClick={onConfirm}>{t('SHARED.TRY_AGAIN')}</Button>
      </Flex>
    </>
  );
};
